/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { PlyrComponent } from 'plyr-react'
import parseHtml from 'html-react-parser'

import Link from '@components/link'

export const HeroBlock = ({ title, content, size = 'regular', backgroundColor = 'white', image, alt }) => (
  <BackgroundImage fluid={image.localFile.childImageSharp.fluid} alt={alt} className={`hero block is-${backgroundColor} is-${size.toLowerCase()}`}>
    <div className="hero-body">
      <div className="container has-text-centered">
        <h3 className="subtitle is-size-3 is-uppercase is-family-secondary has-text-weight-light">{title.text}</h3>
        <div className="content has-text-weight-light">
          {parseHtml(content.html)}
        </div>
      </div>
    </div>
  </BackgroundImage>
)
HeroBlock.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string
}

export const VideoBlock = ({ title, linkText, video, image, alt }) => {
  const [isVideoActive, setVideoActive] = useState(false)

  useEffect(() => {
    setVideoActive(true)
  }, [])

  const sources = {
    title: title.text,
    type: 'video',
    sources: [
      {
        src: video.url,
        type: 'video/mp4',
        size: 1080
      }
    ]
  }
  const options = {
    autoplay: false,
    controls: [
      'play',
      'progress',
      'current-time',
      'duration',
      'mute',
      'volume',
      'fullscreen'
    ]
  }

  if (isVideoActive) return <PlyrComponent sources={sources} options={options} />
  return null
  // return (
  //   <BackgroundImage fluid={image.localFile.childImageSharp.fluid} alt={alt} className="hero block is-medium is-white">
  //     <div className="hero-body">
  //       <div className="container has-text-centered">
  //         {/* <h3 className="subtitle is-size-3 is-uppercase is-family-secondary has-text-weight-light has-text-white">{title.text}</h3> */}
  //         <div className="content has-text-weight-light">
  //           <a className="has-text-white button is-white is-outlined" tabIndex={0} role="button" onClick={() => setVideoActive(true)} onKeyUp={() => setVideoActive(true)}>
  //             {linkText.text}
  //           </a>
  //         </div>
  //       </div>
  //     </div>
  //   </BackgroundImage>
  // )
}
VideoBlock.propTypes = {
  title: PropTypes.object,
  linkText: PropTypes.object,
  video: PropTypes.object,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string
}

export const GridContentBlock = ({ items }) => {
  const CoverBody = ({ content, linkText, link }) => (
    <div className="hero is-medium block-home-grid-content">
      <div className="hero-body">
        <div className="container has-text-centered has-text-white">
          <div className="content has-text-weight-light has-text-white">{parseHtml(content.html)}</div>
          {(link.slug && linkText.html) && <p className="has-text-weight-light is-size-7">{linkText.text}</p>}
        </div>
      </div>
    </div>
  )
  CoverBody.propTypes = {
    content: PropTypes.object.isRequired,
    link: PropTypes.object,
    linkText: PropTypes.object
  }
  return (
    <div className="columns is-multiline is-marginless has-background-primary block">
      {items.map((item, i) => {
        if (!item.background_image.localFile) {
          return (
            <div key={i} className="column is-half">
              <Link to={item.link.slug} lang={item.link.lang}>
                <CoverBody content={item.content} linkText={item.link_text} link={item.link} />
              </Link>
            </div>
          )
        }
        return (
          <BackgroundImage key={i} fluid={item.background_image.localFile.childImageSharp.fluid} className="column is-half">
            <Link to={item.link.slug} lang={item.link.lang}>
              <CoverBody content={item.content} linkText={item.link_text} link={item.link} />
            </Link>
          </BackgroundImage>
        )
      })}
    </div>
  )
}

GridContentBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
}
