import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Header from '@components/header'

import { HeroBlock, VideoBlock, GridContentBlock } from '@components/home-blocks/blocks'

const HomepageTemplate = ({ data: { prismicHomepage: { home, lang } }, pageContext: { alternateLanguages } }) => {
  const { seo, blocks, ...header } = home
  return (
    <Layout lang={lang} alternateLanguages={alternateLanguages}>
      <SEO seo={seo} lang={lang} />
      <Header title={header.header_title.text} text={header.header_text.text} image={header.header_background_image} video={header.header_background_video} />
      <div className="container-fluid">
        {blocks.map(({ id, slice_type: type, primary: block, items }) => (
          <div key={id} id={id}>
            {type === 'hero_block' && <HeroBlock title={block.title} content={block.content} size={block.size} backgroundColor={block.content_background} image={block.background_image} alt={block.background_image.alt} />}
            {type === 'video_block' && <VideoBlock title={block.title} linkText={block.link_text} video={block.video} image={block.background_image} alt={block.background_image.alt} />}
            {type === 'grid_content_block' && <GridContentBlock items={items} />}
          </div>
        ))}
      </div>
    </Layout>
  )
}

HomepageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export default HomepageTemplate

export const query = graphql`query Homepage ($lang: String!) {
    prismicHomepage (lang: {eq: $lang}) {
      lang
      home: data {
        ...HomeSEO
        ...HomeHeader
        blocks: body {
          # ...HomeHeroBlock
          ...HomeVideoBlock
          ...HomeGridContentBlock
        }
      }
    }
  }
`
